<template>
  <div id="app">
    <app-header v-if="$route.meta.hideHeader !== true" />
    <router-view />
    <app-footer v-if="$route.meta.hideFooter !== true" />
    <cookie-law
      theme="dark-lime"
      message="Diese Website verwendet Cookies, um Ihnen die bestmögliche Nutzung unserer Website zu ermöglichen."
      buttonText="Verstanden"
      transitionName="fade">
    </cookie-law>
    <notifications
      class="pb-2"
      group="notify"
      position="bottom right"
      classes="notify"
    />
    <app-new-campaign v-if="isCampaignVisible" />
    <app-new-members v-if="isNewMembersVisible" />
  </div>
</template>

<script>
import header from '@/components/Header.vue'
import footer from '@/components/Footer.vue'
import newCampaign from '@/components/NewCampaign.vue'
import newMembers from '@/components/NewMembers.vue'
import CookieLaw from 'vue-cookie-law'
import { checkDateRange } from '@/scripts/orderDate'

export default {
  components: {
    'app-header': header,
    'app-footer': footer,
    'app-new-campaign': newCampaign,
    'app-new-members': newMembers,
    CookieLaw
  },
  data () {
    return {
      isCampaignVisible: false,
      campaignStartDate: '2024-05-17 00:00',
      campaignEndDate: '2024-06-11 23:59',
      isNewMembersVisible: false,
      newMemberStartDate: '2024-06-12 00:00',
      newMemberEndDate: '2024-12-31 23:59'
    }
  },
  created () {
    this.isCampaignVisible = checkDateRange(this.campaignStartDate, this.campaignEndDate)
    this.isNewMembersVisible = checkDateRange(this.newMemberStartDate, this.newMemberEndDate)
  }
}
</script>

<style lang="scss">
@import '@/styles/app.scss';
body {
  // background-color: #4a637a !important;
  color: aliceblue;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.Cookie {
  background-color: #27445e !important;
}

.Cookie__button {
  background: $success !important;
  &:hover {
    background: lighten($color: $success, $amount: 5) !important;
  }
}

.notify {
  padding: 10px;
  margin: 0 5px 5px;
  font-size: 12px;
  color: #ffffff;
  background: #44a4fc;
  border-left: 5px solid #187fe7;

  &.warn {
    background: #ffb648;
    border-left-color: #f48a06;
  }

  &.error {
    background: #e54d42;
    border-left-color: #b82e24;
  }

  &.success {
    background: #68cd86;
    border-left-color: #42a85f;
  }
}
</style>
