<template>
  <main>
    <h1 class="d-none">Home</h1> <!-- SEO -->
    <div id="background">
      <div class="spruch py-0 py-sm-5 px-2 px-sm-0">
        <p>
          Wer Fische fängt mit Leidenschaft mit Meisterschaft und Wissenschaft<br />
          und hält dabei sich jugendhaft, gewissenhaft und ehrenhaft,<br />
          den reichen Fang mit Maß betreibt,<br />
          sorgt das im Wasser auch was bleibt<br />
          und angelt nicht um Geld und Gunst,<br />
          nein nur aus der Freude und der Kunst,<br />
          der ist wär`s der geringste Knecht, "Sportangler" und auch fischgerecht
        </p>
        <span class="font-weight-bold" style="font-size: 23px;">Petri Heil</span>
      </div>
    </div>
    <order-advertise ref="orderAdvertise" />
  </main>
</template>

<script>
import orderAdvertise from './orderAdvertise.vue'
import { checkOrderDate } from '@/scripts/orderDate'
export default {
  components: {
    orderAdvertise
  },
  mounted () {
    this.init()
    if (checkOrderDate()) {
      this.$refs.orderAdvertise.open()
    }
    window.addEventListener('resize', this.init)
    document.querySelector('meta[name=description]').content = 'Startseite'
  },
  destroyed () {
    window.removeEventListener('resize', this.init)
  },
  methods: {
    init () {
      const hHeight = document.getElementById('headerLogo').offsetHeight
      document.getElementsByTagName('main')[0].style.height = 'calc(100vh - (' + hHeight + 'px + 0.5rem))'
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/styles/app.scss';
main {
  #background {
    background-image: url('/public/static/home_background_filtered.webp');
    background-repeat: no-repeat;
    background-position: top;
    background-size: cover;
    display: flex;
    align-items: center;
    width: 100%;
    height: 100%;
  }
  .spruch {
    color: aliceblue;
    width: 100%;
    text-align: center;
    p, span {
      line-height: 4rem;
      font-size: 20px;
      font-family: cursive;
      font-style: italic;
    }
  }
}

@media (max-width: 320px) {
  main {
    .spruch {
      p, span {
        line-height: 1.9rem;
        // margin-bottom: 0;
      }
    }
  }
}

@media (min-width: 321px) and (max-width: 375px) {
  main {
    .spruch {
      p, span {
        line-height: 2.75rem;
        // margin-bottom: 0;
      }
    }
  }
}

@media (min-width: 376px) and (max-width: 425px) {
  main {
    .spruch {
      p, span {
        line-height: 3rem;
        // margin-bottom: 0;
      }
    }
  }
}

@media (min-width: 426px) and (max-width: 768px) {
  main {
    .spruch {
      p, span {
        line-height: 3.5rem;
        // margin-bottom: 0;
      }
    }
  }
}
</style>
