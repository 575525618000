import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
import Home from '@/views/Home/home.vue'
import Waters from '@/views/Waters/waters.vue'
import firebase from 'firebase/app'
import 'firebase/auth'

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  {
    path: '*',
    component: () => import(/* webpackChunkName: "404" */ '@/views/404.vue'),
    meta: { hideHeader: true, hideFooter: true }
  },
  {
    path: '/',
    redirect: { name: 'Home' }
  },
  {
    path: '/home',
    name: 'Home',
    component: Home
  },
  {
    path: '/ueberUns',
    name: 'About',
    component: () => import(/* webpackChunkName: "About" */ '@/views/About/about.vue')
  },
  {
    path: '/mitglieder',
    name: 'Members',
    component: () => import(/* webpackChunkName: "Members" */ '@/views/Members/members.vue')
  },
  {
    path: '/projekte',
    name: 'Projects',
    component: () => import(/* webpackChunkName: "Projects" */ '@/views/Projects/projects.vue')
  },
  {
    path: '/events',
    name: 'Events',
    component: () => import(/* webpackChunkName: "Events" */ '@/views/Events/events.vue')
  },
  {
    path: '/gewaesser',
    name: 'Waters',
    component: Waters
  },
  {
    path: '/gallerie',
    name: 'Gallery',
    component: () => import(/* webpackChunkName: "Gallery" */ '@/views/Gallery/gallery.vue')
  },
  {
    path: '/vorbestellung',
    name: 'Order',
    component: () => import(/* webpackChunkName: "Order" */ '@/views/Order/order.vue')
  },
  {
    path: '/vorbestellung/fertig',
    name: 'Landingpage',
    component: () => import(/* webpackChunkName: "Landingpage" */ '@/views/Order/Landingpage/landingpage.vue')
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import(/* webpackChunkName: "Login" */ '@/views/Admin/login.vue')
  },
  {
    path: '/admin',
    name: 'Admin',
    component: () => import(/* webpackChunkName: "Admin" */ '@/views/Admin/admin.vue'),
    meta: { requiresAuth: true, headerAdmin: true }
  },
  {
    path: '/kontakt-impressum',
    name: 'Contact-Imprint',
    component: () => import(/* webpackChunkName: "Contact-Imprint" */ '@/views/Contact-Imprint/contact-imprint.vue')
  },
  {
    path: '/datenschutz',
    name: 'Privacy',
    component: () => import(/* webpackChunkName: "Privacy" */ '@/views/Privacy/privacy.vue')
  },
  {
    path: '/nutzungsbedingungen',
    name: 'TermsOfService',
    component: () => import(/* webpackChunkName: "Privacy" */ '@/views/TermsOfService/termsOfService.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  const requiresAuth = to.matched.some(record => record.meta.requiresAuth)
  const isAuthenticated = firebase.auth().currentUser
  if (requiresAuth && !isAuthenticated) {
    next('/login')
  } else {
    next()
  }
})

export default router
