<template>
  <b-modal ref="newCampaign" size="xl" :title="`Fischfest ${new Date().getFullYear()}`" hide-footer centered scrollable>
    <div class="d-flex justify-content-between align-items-center flex-column flex-lg-row">
      <div class="image-container">
        <img :src="require('@/assets/Fischfest_1.jpg')" alt="Bild 1" class="img-fluid">
      </div>
      <div class="image-container">
        <img :src="require('@/assets/Fischfest_2.jpg')" alt="Bild 2" class="img-fluid">
      </div>
    </div>
  </b-modal>
</template>

<script>
export default {
  mounted () {
    this.open()
  },
  watch: {
    '$route.name': function () {
      this.open()
    }
  },
  methods: {
    open () {
      if (this.$route.name === 'Home' || this.$route.name === 'Order') {
        this.$refs.newCampaign.show()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.flex-lg-row {
  .image-container {
    width: 80%;
    margin: 10px;
  }
}
</style>
