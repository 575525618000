<template>
  <footer id="footer" class="py-3 text-white" :class="{ 'position-fixed fixed-bottom w-100': fixed }">
    <b-container class="pt-3 pb-1" fluid="md">
        <b-row class="justify-content-center pb-3">
          <b-col cols="12" md="4">
            <router-link :to="{ name: 'TermsOfService' }">Nutzungsbedingungen</router-link>
          </b-col>
          <b-col cols="12" md="4">
            <router-link :to="{ name: 'Privacy' }">Datenschutz</router-link>
          </b-col>
          <b-col cols="12" md="4">
            <router-link :to="{ name: 'Contact-Imprint' }">Kontakt & Impressum</router-link>
          </b-col>
        </b-row>
        <b-row class="justify-content-center">
          <b-col>
            <small>&copy; Copyright {{ currentYear }}, SAC Ebenhausen. Alle Rechte vorbehalten</small>
          </b-col>
        </b-row>
    </b-container>
  </footer>
</template>

<script>
export default {
  data () {
    return {
      fixed: false,
      currentYear: new Date().getFullYear()
    }
  },
  mounted () {
    window.addEventListener('resize', this.fixFooter)
  },
  watch: {
    $route () {
      this.fixFooter()
    }
  },
  methods: {
    fixFooter () {
      const mainHeight = document.getElementsByTagName('main')[0].offsetHeight + document.getElementsByTagName('header')[0].offsetHeight
      if (this.$route.name === 'About' && window.innerHeight > mainHeight) {
        this.fixed = true
      } else {
        this.fixed = false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
footer {
  background-color: #5D85A8;
  box-shadow: 0px 0px 4px 2px #5d85a8;
  .row {
    div {
      text-align: center;
    }
  }
  a {
    color: #ffffff80;
    &:hover {
      color: #ffffffbf;
      text-decoration: none;
    }
  }
}
</style>
