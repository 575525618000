import Vue from 'vue'
import App from './App.vue'
import router from './router'
import { BootstrapVue, BootstrapVueIcons } from 'bootstrap-vue'
import notifications from 'vue-notification'
import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/database'
import 'firebase/storage'
// import 'firebase/analytics'
// import 'firebase/firestore'

import '@/styles/app.scss'
Vue.use(BootstrapVue)
Vue.use(BootstrapVueIcons)
Vue.use(notifications)

Vue.config.productionTip = false
Vue.prototype.$firebase = firebase

// Vue.prototype.$shownCampaign = false

// Filter
Vue.filter('parseEmail', function (email: string): string {
  if (!email) return ''
  const endIndex = email.indexOf('@')
  email = email.substr(0, endIndex)

  return email
})

Vue.filter('booleanToWord', function (bool: boolean): string {
  return bool ? 'Ja' : 'Nein'
})

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: 'AIzaSyALQhBRlGKOkkJ1GwOmSdThyeYSCACC8gQ',
  authDomain: 'sac-ebenhausen.firebaseapp.com',
  databaseURL: 'https://sac-ebenhausen-default-rtdb.europe-west1.firebasedatabase.app',
  projectId: 'sac-ebenhausen',
  storageBucket: 'sac-ebenhausen.appspot.com',
  messagingSenderId: '916210436207',
  appId: '1:916210436207:web:3b88a80c9917c07b03e59a',
  measurementId: 'G-GLPLEXPCSQ'
}
// Initialize Firebase
firebase.initializeApp(firebaseConfig)

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
