<template>
  <header id="header">
    <b-navbar toggleable="xl" type="dark" class="navbar">
      <b-navbar-brand :to="{ name: 'Home' }" id="headerLogo">
        <img
          src="@/assets/logo.webp"
          width="80"
          height="88"
          class="d-inline-block align-top"
          alt="logo"
        />
        <div class="d-inline-block logo pl-2">
          <p class="m-0">Sport-Angler-Club</p>
          <p class="m-0">Petri Heil 66</p>
          <p class="m-0">Ebenhausen e.V.</p>
        </div>
      </b-navbar-brand>
      <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>
      <b-collapse id="nav-collapse" v-model="navVisible" class="text-center text-xl-unset" is-nav>
        <!-- <b-collapse id="nav-collapse" class="text-center text-xl-1unset pt-3 pt-xl-0 pb-2 pb-xl-0" is-nav> -->
        <!-- Right aligned nav items -->
        <b-navbar-nav class="mx-auto pt-3 pt-xl-0">
          <b-nav-item active-class="active-color" class="pr-xl-5" :to="{ name: 'Home' }">Home</b-nav-item>
          <b-nav-item active-class="active-color" class="pr-xl-5" :to="{ name: 'About' }">Über uns</b-nav-item>
          <b-nav-item active-class="active-color" class="pr-xl-5" :to="{ name: 'Members' }">Mitglieder & Vorstand</b-nav-item>
          <b-nav-item active-class="active-color" class="pr-xl-5" :to="{ name: 'Projects' }">Projekte</b-nav-item>
          <b-nav-item active-class="active-color" class="pr-xl-5" :to="{ name: 'Events' }">Termine</b-nav-item>
          <b-nav-item active-class="active-color" class="pr-xl-5" :to="{ name: 'Waters' }">Unsere Gewässer</b-nav-item>
          <b-nav-item active-class="active-color" class="pr-xl-5" :to="{ name: 'Gallery' }">Galerie</b-nav-item>
        </b-navbar-nav>
        <div class="pt-2 pt-xl-0 pb-3 pb-xl-0">
          <b-link v-if="$route.meta.headerAdmin === true" @click="signOut()" type="button" class="text-danger" style="text-decoration: none; font-size: 1.1em;">
            <b-icon-door-open-fill />
            Abmelden
          </b-link>
          <b-link v-else :to="{ name: 'Order' }" @click="toggleNavbar" class="special-active-color" style="text-decoration: none; font-size: 1.1em;">
            Fisch bestellen
            <b-icon-chevron-double-right />
          </b-link>
        </div>
      </b-collapse>
    </b-navbar>
  </header>
</template>

<script>
import { showSuccessMessage, showErrorMessage } from '@/scripts/notification'

export default {
  data () {
    return {
      navVisible: false
    }
  },

  methods: {
    signOut () {
      try {
        this.$firebase.auth().signOut().then(() => {
          this.$router.replace({ name: 'Login' }).then(() => {
            showSuccessMessage('Erfolgreich ausgeloggt')
          })
        })
      } catch (err) {
        showErrorMessage(err.message)
      }
    },
    toggleNavbar () {
      if (this.navVisible) {
        this.navVisible = false
      }
    }
    // document.getElementsByClassName('spruch')[0].style.top = 'calc(100vh - (' + document.getElementById('nav-collapse').offsetHeight + 'px))'
  }
}
</script>

<style lang="scss" scoped>
.navbar {
  background-color: #5D85A8;
  box-shadow: 0px 0px 4px 2px #5d85a8;
  z-index: 10;

  .logo {
    font-family: 'Times New Roman';
    font-weight: bold;
    font-style: italic;
    letter-spacing: 0em;
  }

  .navbar-nav {
    .nav-link {
      font-weight: 600 !important;
    }
  }

  .active-color {
    color: lighten($color: rgb(0, 78, 0), $amount: 5) !important;
    &:hover {
      color: lighten($color: rgb(0, 78, 0), $amount: 7) !important;
    }
  }

  .special-active-color {
    color: rgb(0, 78, 0) !important;
    font-weight: 600 !important;
    &:hover {
      color: lighten($color: rgb(0, 78, 0), $amount: 7) !important;
    }
  }
}

@media (max-width: 374px) {
  .navbar-brand {
    display: flex;
    align-items: center;
    .logo {
      font-size: 0.8em !important;
    }
  }
}
</style>
