import Vue from 'vue'

function showMessage (type: string, text: string, title: string) {
  Vue.notify({
    group: 'notify',
    title: title,
    text: text,
    type: type
  })
}

export function showSuccessMessage (text: string, title?: string) {
  if (title === undefined) {
    title = 'Erfolg'
  }
  showMessage('success', text, title)
}

export function showErrorMessage (text: string, title?: string) {
  if (title === undefined) {
    title = 'Fehler'
  }
  showMessage('error', text, title)
}

export function showWarningMessage (text: string, title?: string) {
  if (title === undefined) {
    title = 'Warnung'
  }
  showMessage('warning', text, title)
}

export function showInfoMessage (text: string, title?: string) {
  if (title === undefined) {
    title = 'Information'
  }
  showMessage('info', text, title)
}
